/*eslint-disable */
// import UserLoyaltyModel from '../../Model/UserLoyalty'
import request from '../../Utils/curl'

let UserLoyaltys = {
  /**
   * user_loyaltyList
   */
  async user_loyaltyList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "user_loyalty_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at user_loyaltyList() and Exception:',err.message)
    }
  },

  /**
   * user_loyaltyView
   */
  async user_loyaltyView (context, userLoyaltyId) {
    try {      
      let post_data = new FormData();
      post_data.append('loy_id', userLoyaltyId);
      return await request.curl(context, "user_loyalty_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at user_loyaltyView() and Exception:",err.message)
    }    
  },

  /**
   * user_loyaltyAdd
   */
  async user_loyaltyAdd (context, user_loyaltyObj) {
    try{
      let post_data = new FormData();
    
      for (let key in user_loyaltyObj) {
        post_data.append(key, user_loyaltyObj[key]);
      }

      return await request.curl(context, "user_loyalty_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_loyaltyAdd() and Exception:',err.message)
    }
  },

  /**
   * user_loyaltyEdit
   */
  async user_loyaltyEdit (context, user_loyaltyObj) {
    try{
      let post_data = new FormData();
    
      for (let key in user_loyaltyObj) {
        post_data.append(key, user_loyaltyObj[key]);
      }

      return await request.curl(context, "user_loyalty_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_loyaltyEdit() and Exception:',err.message)
    }
  },

  /**
   * user_loyaltyDelete
   */
  async user_loyaltyDelete (context, userLoyaltyId) {
    try{
      let post_data = new FormData();
      
      post_data.append('loy_id', userLoyaltyId);

      return await request.curl(context, "user_loyalty_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at user_loyaltyDelete() and Exception:',err.message)
    }
  }
}

export {
  UserLoyaltys
}
