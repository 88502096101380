/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationmodule_name">
                    {{ cvModuleNameLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_name" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_obj_id">
                    {{ cvModuleObjIdLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_obj_id" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_action">
                    {{ cvModuleActionLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_action" type="text" class="form-control" required />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmodule_points">
                    {{ cvModulePointsLabel }}</label>
                  <input v-model="vmUserLoyaltyFormData.module_points" type="text" class="form-control" required />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="user_loyaltyAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { UserLoyaltys } from "../../../FackApi/api/UserLoyalty.js"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "UserLoyaltyAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add user Loyalty",
      cvCardSubHeader: "Add user Loyalty",
      cvSubmitBtn: "Add",
      cvModuleNameLabel: "module name",
      cvModuleObjIdLabel: "module obj id",
      cvModuleActionLabel: "module action",
      cvModulePointsLabel: "module points",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "UserLoyalty Add Response",
      vmUserLoyaltyFormData: Object.assign({}, this.initFormData()),
      vmUserLoyaltyDesc: null,
      vmUserLoyaltyType: null
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_name": "",
        "module_obj_id": "",
        "module_action": "",
        "module_points": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserLoyaltyFormData) {
          if (!this.vmUserLoyaltyFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * user_loyaltyAdd
     */
    async user_loyaltyAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let userLoyaltyAddResp = await UserLoyaltys.user_loyaltyAdd(this, this.vmUserLoyaltyFormData)
        await ApiResponse.responseMessageDisplay(this, userLoyaltyAddResp)
        if (userLoyaltyAddResp && !userLoyaltyAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseUserLoyaltyAddModal", userLoyaltyAddResp.resp_data)
        }
        this.vmUserLoyaltyFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at user_loyaltyAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
